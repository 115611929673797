import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import './PaymentCancel.css';

export default function PaymentCancel() {
  const navigate = useNavigate();
  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Box className="cancelBox">
      <CancelIcon className="cancelIcon" />
      <Typography variant="h4" component="h1" className="cancelText" gutterBottom>
        Payment Cancelled
      </Typography>
      <Typography variant="body1" className="cancelMessage">
        Your payment was cancelled. Please try again if you wish to complete the purchase.
      </Typography>
      <LoadingButton
        className="cancelButton"
        variant="contained"
        onClick={handleGoHome}
      >
        GO TO Homepage
      </LoadingButton>
    </Box>
  );
}
