import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import usePagination from '@mui/material/usePagination';
import { styled, useTheme } from '@mui/material/styles';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import { getProductsData } from '../../actions';
import { pageSize } from '../../../../variables';
import './Pagination.css';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreIds } from '../../../../utils/getStoreIds';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center'
});

const Pagination = ({ count }) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { allStoreLocations } = useSelector(state => state.storeReducer);
  const { selectedItemsFilter, sortby } = useSelector(
    state => state.productsReducer
  );

  const handleChange = (e, page) => {
    setPage(page);
    dispatch(
      getProductsData({
        pageId: page,
        storeId: getStoreIds(
          allStoreLocations.map(s => s.id),
          selectedItemsFilter
        )
      })
    );
  };
  const { items } = usePagination({
    count: parseInt(Math.ceil(count / pageSize)),
    onChange: (e, page) => handleChange(e, page),
    page
  });

  const prvNxtDisable = (page, type) => {
    if (type === 'next' && page > parseInt(Math.ceil(count / pageSize))) {
      return true;
    }
    if (type === 'previous' && page < 1) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setPage(1);
  }, [selectedItemsFilter, sortby]);

  return (
    <nav className="pg-bg-color">
      <List className="pagination-list">
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button
                className="pagination-btn "
                type="button"
                style={{
                  color: selected ? '#ff5943' : undefined
                }}
                {...item}
                disabled={selected}
              >
                {page}
              </button>
            );
          } else {
            if (mdScreen) {
              children = (
                <button
                  className={`pagination-nav-btn  ${prvNxtDisable(page, type) ? 'disable' : ''
                    }`}
                  type="button"
                  {...item}
                  disabled={prvNxtDisable(page, type)}
                >
                  {type === 'next' ? (
                    <NavigateNext fontSize="large" />
                  ) : (
                    <NavigateBefore fontSize="large" />
                  )}
                </button>
              );
            } else {
              children = (
                <button
                  className={`pg-btn-prv-nxt ${prvNxtDisable(page, type) ? 'disable' : ''
                    } `}
                  type="button"
                  {...item}
                  disabled={prvNxtDisable(page, type)}
                >
                  {type}
                </button>
              );
            }
          }

          return <li key={index}>{children}</li>;
        })}
      </List>
    </nav>
  );
};

export default Pagination;
