import { Box, Stack, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Stripe from './Stripe';

const QuickBuy = ({ product }) => {
  const navigate = useNavigate();
  const endDate = new Date(product.displayExpireDate);

  return (
    <Stack
      alignItems={{ xs: 'center', md: 'flex-start' }}
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      padding={{ xs: 0, md: '0px 35px' }}
    >
      <Box
        component="img"
        src={product?.images?.[0] || product?.image}
        alt={product.productName}
        borderRadius="10px"
        sx={{ my: { xs: 3, md: 0 }, mr: { xs: 0, md: 3.5 } }}
        width={{ xs: '250px', md: '210px' }}
        height={{ xs: 'auto', md: '174px' }}
      />
      <Stack
        spacing={6}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Box>
          <Typography
            component="h1"
            fontSize="20px"
            fontWeight="700"
            lineHeight="28px"
            letterSpacing="-0.333333px"
            pb={2}
            sx={{ cursor: 'pointer' }}
            onClick={() =>
              navigate(
                `/product/${product.type}/${product.productId}/${product.productName}`
              )
            }
          >
            {product.productName}
          </Typography>
          <Typography
            component="h2"
            fontSize="30px"
            fontWeight="700"
            lineHeight="36.16px"
          >
            <Typography
              component="span"
              fontSize="16px"
              fontWeight="400"
              lineHeight="18.75px"
            >
              Price:{' '}
            </Typography>
            ৳{product.price}
          </Typography>
          <Typography>
            <div className="Expire-date">
              {
                <>
                  <strong>Available on:</strong> {endDate.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}
                </>
              }
            </div>
          </Typography>
          <Grid item xs={12}>
            <div className="product-owner">
              Shop/ owner:{' '}
              <span className="product-owner-name">
                {product.storeName}
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
              <div className="product-owner">
                Store Locations:{' '}
                <span className="product-owner-name">
                  {product.storeAddress}
                </span>
              </div>
            </Grid>
        </Box>
        <Box
          sx={{
            width: { xs: '251px', md: '394px' },
            pb: { xs: '10px', md: '30px' }
          }}
        >
          < Stripe product={product} isCenterAligned ={true}/>
        </Box>
      </Stack>
    </Stack>
  );
};

export default QuickBuy;
