import thriftzee from '../../services/thrift';
import { setBidHistory } from './reducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { csrf } from '../../utils/csrf';

export const placeBid = createAsyncThunk(
  'bid/placeBid',
  async (data, { dispatch, getState }) => {
    const {
      loginReducer: {
        userData: { picture, nickname, email },
        token
      }
    } = getState();

    const { productId, storeId, bidAmount } = data;

    const payload = {
      storeId,
      userPic: picture,
      userName: nickname,
      userEmail:email,
      bidAmount: parseInt(bidAmount)
    };
    const response = await thriftzee.post(
      `/products/${productId}/bids`,
      payload,
      {
        headers: { authorization: `Bearer ${token}` }
      }
    );
    window.newrelic &&
      window.newrelic.addPageAction('PLACE_BID', response.data.data);
    return response.data.data;
  }
);

export const getProductBidHistory = productId => async (dispatch, getState) => {
  const {
    loginReducer: { token }
  } = getState();
  let isPublic = false;
  let bearerToken;

  if (!token) {
    const csrfToken = await csrf();
    bearerToken = csrfToken;
    isPublic = true;
  }
  const config = {
    headers: {
      Authorization: `Bearer ${bearerToken}`
    }
  };

  const { data } = isPublic
    ? await thriftzee.get(`/public/products/${productId}/bids`, config)
    : await thriftzee.get(`/products/${productId}/bids`, config);
  dispatch(setBidHistory(data));
};

export const purchasedProduct = createAsyncThunk(
  'productReducer/purchasedProduct',
  async (data, { getState }) => {
    const { token } = getState().loginReducer;
    const response = await thriftzee.post(`/public/products/payment/session`, data,
      { headers: { authorization: `Bearer ${token}` } }
    );
    return response;
  }
);

export const purchaseBidProduct = createAsyncThunk(
  'productDetailsReducer/purchaseBidProduct',
  async (data, { getState }) => {
    const { token, userData } = getState().loginReducer;
    const response = await thriftzee.post(
      '/auction/payment',
      {
        ...data,
        userEmail: userData.email
      },
      { headers: { authorization: `Bearer ${token}` } }
    );

    return response.data.data;
  }
);

export const getProductDetails = createAsyncThunk(
  'productDetails/fetchProductDetails',
  async (productId, { getState }) => {
    const {
      loginReducer: { token }
    } = getState();
    let isPublic = false;
    let bearerToken;

    if (!token) {
      const csrfToken = await csrf();
      bearerToken = csrfToken;
      isPublic = true;
    } else bearerToken = token;
    const config = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };
    const response = await thriftzee.get(
      `${isPublic ? '/public' : ''}/products/${productId}`,
      config
    );
    return response.data.data;
  }
);
