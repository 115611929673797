import React, { useEffect } from 'react';
import { Container } from '@mui/material';
//import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
//import { featureContent } from './redux/reducer';
import { useNavigate } from 'react-router-dom';
import { getDefaultFeatureProducts, getFeatureProducts } from './redux/action';
import CommonLayout from './../../Layouts/CommonLayout';
import HeroSection from '../../Components/refactor/HeroSection';
import { Instruction } from './components/Index.js';
import { setUserPosition, userLocation } from '../../reducers/userPosition';
//import CircularProgressLoader from '../../Components/CircularProgressLoader';
import { setAuthResult, setToken, setUserData } from '../Login/reducer';
// import lock from '../../configs/authLock';
import { getRedirectLink } from '../../utils/redirect';
import './LandingPageStyle.css';
import getUserPoll from '../../utils/getUserPoll';
import UserPool from '../../configs/cognitoPoll';
// import { type } from '@testing-library/user-event/dist/type';

const LandingPage = () => {
  // lock.hide();
  const navigate = useNavigate();
  //const theme = useTheme();
  const dispatch = useDispatch();
  const { position } = useSelector(userLocation);
  //const { featureProductStatus } = useSelector(featureContent);
  //const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!position?.latitude && !position?.longitude) {
      dispatch(getDefaultFeatureProducts());
    }
  }, [dispatch, position?.latitude, position?.longitude]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      dispatch(setUserPosition({ latitude, longitude }));
    });
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(position).length) {
      dispatch(getFeatureProducts());
    }
  }, [dispatch, position]);

  useEffect(() => {
    // lock.on('authenticated', function (authResult) {
    //   lock.hide();
    //   const link = getRedirectLink() || '/';
    //   navigate(link);
    //   dispatch(setAuthResult(authResult));
    //   lock.getUserInfo(authResult.accessToken, function (error, profileResult) {
    //     dispatch(setToken(authResult.accessToken));
    //     dispatch(setUserData(profileResult));
    //     if (error) {
    //       return;
    //     }
    //   });
    // });
    getUserPoll(UserPool)
      .then(data => {
        const link = getRedirectLink() || '/';
        // console.log('link::::::::', link);

        dispatch(setAuthResult(data));
        dispatch(setToken(data?.accessToken?.jwtToken));
        dispatch(setUserData(data?.userMeta));
        navigate(link);
        // console.log(data?.accessToken?.jwtToken);
        // console.log(data?.userMeta);
      })
      .catch(error => {
        // console.log(error);
        return;
      });
  }, [dispatch, navigate]);

  return (
    <>
      <CommonLayout>
        <HeroSection />

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Instruction />
        </Container>
        {/* {featureProductStatus === 'success' ? (
          <>
            <div
              style={{
                backgroundColor: mdScreen ? '' : '#f6f6f6',
                paddingTop: mdScreen ? '' : '90px'
              }}
            >
              <Container maxWidth="lg">
                <DefaultLocation />
              </Container>
            </div>
            <div style={{ backgroundColor: mdScreen ? '' : '#f6f6f6' }}>
              <Container maxWidth="lg" sx={{ py: { xs: 0, md: 4 } }}>
                <Advertise />
              </Container>
            </div>
          </>
        ) : (
          <CircularProgressLoader />
        )} */}
      </CommonLayout>
    </>
  );
};

export default LandingPage;
