import React from 'react';
import { Button, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LeftSection = () => {
  const location = useLocation();
  const { pathname } = location;

  const navigate = useNavigate();
  const { selectedStores } = useSelector(state => state.storeReducer);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <div className="font-white hero-left-header-text">
            REGISTER FOR FREE & START BUYING NOW!
          </div>
        </Grid>
        <Grid item className="font-white hero-left-sub-header-text">
          Browse through a wide range of products from your desired shops and
          buy instantly from the convenience of your home.
        </Grid>
        <Grid
          item
          sx={{ py: { xs: '15px !important', md: '30px !important' } }}
          className="hero-get-started-btn"
        >
          {pathname === '/' && (
            <Button
              className="hero-btn"
              sx={{
                ':hover': {
                  bgcolor: '#FFFFFF !important',
                  color: '#193C46 !important'
                },
                py: 1
              }}
              onClick={() =>
                !selectedStores.length
                  ? navigate('/home')
                  : navigate('/products')
              }
            >
              Get Started Now
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default LeftSection;
