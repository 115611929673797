import React, { useState } from 'react';
import { CardMedia, Dialog, IconButton, Box } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageSlider.css';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      <span>&#10095;</span>
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      <span>&#10094;</span>
    </div>
  );
};

const ImageSlider = ({ images }) => {
  const [open, setOpen] = useState(false);

  const handleImageClick = (index) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const settings = {
    dots: true,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Box>
      <Slider {...settings}>
        {(images || []).map((image, index) => (
          <div key={index} onClick={() => handleImageClick(index)}>
            <CardMedia
              component="img"
              className="custom-img"
              image={image}
              alt={`Product Image ${index + 1}`}
            />
          </div>
        ))}
      </Slider>

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        classes={{ paper: 'lightbox-dialog' }}
      >
        <Box position="relative">
          <IconButton
            onClick={handleClose}
            className="close-button"
          >
            <span>&#10005;</span>
          </IconButton>
          <Slider {...settings}>
            {(images || []).map((image, index) => (
              <div key={index}>
                <Box
                  className="lightbox-container"
                >
                  <CardMedia
                    component="img"
                    className="lightbox-image"
                    image={image}
                    alt={`Lightbox Image ${index + 1}`}
                  />
                </Box>
              </div>
            ))}
          </Slider>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ImageSlider;
