import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { purchasedProduct } from '../../Modules/ProductDetails/actions';
import { LoadingButton } from '@mui/lab';
import './Stripe.css';
import { CircularProgress } from '@mui/material';

const Stripe = (product) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorText, setErrorText] = useState('');
    const [loading, setLoading] = useState(false);
    const loginReducer = useSelector(state => state.loginReducer);

    const { productName, price, images, productId } = product.product;

    const handleBtnDisable = () => {
        return !(productName && price && images.length) || loading;
    };

    const handlePayment = async () => {
        if (handleBtnDisable()) {
            setErrorText('Please fill in all required fields.');
            return;
        }

        setLoading(true);

        setTimeout(async () => {
            const baseUrl = `${window.location.protocol}//${window.location.host}`;

            try {
                const responsePayment = await dispatch(
                    purchasedProduct({
                        successUrl: `${baseUrl}/payment-success`,
                        cancelUrl: `${baseUrl}/payment-cancel`,
                        currency: 'usd',
                        "custom:storeId": loginReducer.userData["custom:storeId"],
                        "custom:user_id": loginReducer.userData["custom:user_id"],
                        paymentFor: 'buyProduct',
                        productId,
                    })
                ).unwrap();

                if (responsePayment.data.status === 'success') {
                    navigate('/checkout', {
                        state: { checkoutUrl: responsePayment.data.data.url }
                    });
                } else {
                    console.error('Payment failed:', responsePayment.data.message);
                    setErrorText(responsePayment.data.message);
                }
            } catch (error) {
                console.error('Failed to process payment:', error);
                setErrorText('Failed to process payment. Please try again later.');
            } finally {
                setLoading(false);
            }
        }, 1000);
    };


    return (
        <div className={product.isCenterAligned ? 'button-not-center-container' : 'button-container'}>
            <LoadingButton
                className="add-product-save-btn"
                variant="contained"
                onClick={handlePayment}
                //loading={loading}
                disabled={handleBtnDisable()}
            >
                Buy
                {loading && (
                    <CircularProgress
                        className="circular-progress"
                        color="inherit"
                    />
                )}

            </LoadingButton>
            {errorText && <div className="error-text">{errorText}</div>}
        </div>
    );
};

export default Stripe;
