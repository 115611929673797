import { createSlice } from '@reduxjs/toolkit';
import { getProductsData } from './actions';
import { sortingCriteria } from '../../variables';

const initialState = {
  products: [],
  filterProducts: [],
  selectedItemsFilter: ['available'],
  sortby: sortingCriteria.DEFAULT_DATE_DESC,
  totalCount: 0,
  status: 'initial',
  error: ''
};

export const productsSlice = createSlice({
  name: 'productsReducer',
  initialState,
  reducers: {
    setSelectedItemsFilter: (state, action) => ({
      ...state,
      selectedItemsFilter: action.payload
    }),
    setFilterProducts: (state, action) => ({
      ...state,
      filterProducts: action.payload
    }),
    resetProducts: (state, action) => ({
      ...state,
      products: [],
      filterProducts: [],
      status: 'initial',
      error: ''
    }),
    setSortby: (state, action) => ({
      ...state,
      sortby: action.payload
    })
  },
  extraReducers: {
    [getProductsData.pending]: (state, action) => ({
      ...state,
      status: 'pending'
    }),
    [getProductsData.fulfilled]: (state, action) => ({
      ...state,
      status: 'success',
      products: action.payload.products,
      filterProducts: action.payload.products,
      totalCount: parseInt(action.payload.count)
    }),
    [getProductsData.rejected]: (state, action) => ({
      ...state,
      status: 'error',
      error: action.error.messsage
    })
  }
});

const { reducer, actions } = productsSlice;
export const {
  setFilterProducts,
  setSelectedItemsFilter,
  resetProducts,
  setSortby
} = actions;
export default reducer;
