import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthLayout from './Layouts/AuthLayout';
import ProductsLayout from './Layouts/ProductsLayout';
import Login from './Modules/Login/Login';
import ProductLists from './Modules/ProductLists/ProductLists';
import Dashboard from './Modules/Dashboard';
import About from './Modules/About';
import Contact from './Modules/Contact/ContactLayout';
//import BidHistory from './Modules/bidHistory/BidHistory';
import PurchaseHistory from './Modules/purchaseHistory/PurchaseHistory';
import ProductDetailsLayOut from './Modules/ProductDetails/ProductDetailsLayOut';
import LandingPage from './Modules/LandingPage';
import Privacy from './Modules/Privacy';
import ScrollToTop from './Components/ScrollToTop';
import PrivateRoutes from './Components/PrivateRoutes';
import ForgotPassword from './Modules/ForgotPassword/ForgotPassword';
import Checkout from './Modules/Payment/CheckoutForm';
import PaymentCancel from './Modules/Payment/PaymentCancel';
import PaymentSuccess from './Modules/Payment/PaymentSuccess';

const AppRouter = props => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<AuthLayout />}>
          <Route exact index element={<Login />} />
        </Route>
        <Route path="/forgot-password" element={<AuthLayout />}>
          <Route exact index element={<ForgotPassword />} />
        </Route>
        <Route exact path="Home" element={<ProductsLayout />}>
          <Route exact index element={<Dashboard />} />
        </Route>
        <Route
          exact
          path="products"
          key="all_products"
          element={<ProductsLayout />}
        >
          <Route
            exact
            index
            key="all_products1"
            element={<ProductLists type="all" />}
          />
        </Route>
        {/* <Route
          exact
          path="auction"
          key="auction_products"
          element={<ProductsLayout />}
        >
          <Route
            exact
            index
            key="auction_products1"
            element={<ProductLists type="auction" />}
          />
        </Route> */}
        <Route
          exact
          path="buy"
          key="buy_now_products"
          element={<ProductsLayout />}
        >
          <Route
            exact
            index
            key="buy_now_products1"
            element={<ProductLists type="buy" />}
          />
        </Route>

        <Route path="/about" element={<About />} />

        <Route path="contact" element={<Contact />} />
        <Route
          exact
          path="product/buy/:id/:name"
          element={<ProductDetailsLayOut />}
        ></Route>
        {/* <Route
          exact
          path="product/auction/:id/:name"
          element={<ProductDetailsLayOut />}
        ></Route> */}
        <Route element={<PrivateRoutes />}>
          <Route
            exact
            path="purchase-history"
            element={<PurchaseHistory />}
          ></Route>
          {/* <Route exact path="bid-history" element={<BidHistory />}></Route> */}
        </Route>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-cancel" element={<PaymentCancel />} />
        <Route path="/checkout" element={<Checkout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
