import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import './PaymentSuccess.css';
import { useDispatch, useSelector } from 'react-redux';
import { getProductsData } from '../ProductLists/actions';

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeReducer = useSelector(state => state.storeReducer);
  const [loading, setLoading] = useState(false);

  const handleGoHome = async () => {
    setLoading(true);
    try {
      await dispatch(getProductsData({
        storeId: storeReducer.selectedStores.map(
          selectedStore => selectedStore.id
        ), isRefresh: true
      }));
      navigate('/purchase-history');
    } catch (error) {
      console.error("Failed to update sold status:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className="successBox">
      <CheckCircleOutlineIcon className="successIcon" />
      <Typography variant="h4" component="h1" className="successText" gutterBottom>
        Payment Successful
      </Typography>
      <Typography variant="body1">
        Thank you for your purchase! Your payment was successful.
      </Typography>

      <LoadingButton
        className="successBtn"
        variant="contained"
        onClick={handleGoHome}
        disabled={loading}
      >
        GO TO Homepage
        {loading && (
          <CircularProgress
            className="circular-progress"
            color="inherit"
          />
        )}
      </LoadingButton>
    </Box>
  );
}
