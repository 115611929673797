import thriftzee from '../../../../services/thrift';
import { csrf } from '../../../../utils/csrf';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const addSubscriber = createAsyncThunk(
    'subscriberReducer/addSuscriber',
    async (data, { getState }) => {
        const csrfToken = await csrf();
        const bearerToken = csrfToken;
        const response = await thriftzee.post(`/public/newsletter/subscribe`, data,
            { headers: { authorization: `Bearer ${bearerToken}` } }
        );
        return response;
    }
);