import React from 'react';
import { Container, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CommonLayout from '../../Layouts/CommonLayout';
import CommonHeader from '../../Components/CommonHeader';

import './About.css';

const About = () => {
  document.title = 'Thriftzee Privacy policy';
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <CommonLayout>
      <CommonHeader>About Us</CommonHeader>
      <div className={mdScreen ? 'bid-history-mb' : 'bid-history-lg'}>
        <Container maxWidth="lg" sx={{ py: 1 }}>
          <div className="container-div-about">
            <div className="content">
              <h1 className="header">The Thriftzee Story</h1>
              <p>
                Whether a seasoned or a naive shopper, roaming around thrift
                stores and sighing over not finding anything "good enough" to
                buy is likely to happen. Customers are out of options in
                choosing the products they need. There were a lot of buzzes
                around. Our thrift-star-entrepreneurs thought, "Well, we can do
                something about it!
              </p>

              <p>
                Soon after, they came up with the idea of bringing as many
                thrift stores under one umbrella to offer numerous and
                categorized products online for a better buying experience. When
                the concept turns into reality, Thirftzee finds its feet on the
                ground.
              </p>
              <p>
                Since its inception, with its crafty and innovative business
                model, Thriftzee has been attracting thrift stores to its portal
                and enabling them to exhibit their products. What Thriftzee is
                up to is offering numerous options for customers and inciting a
                shopping frenzy!
              </p>
              <p>
                Besides the regular features like buying and selling, Thriftzee
                offers  <b> "Quick Buy"</b>
                options to facilitate thrift-buying as conveniently as possible.
                On top of that, Thriftzee is in continuous full swing to add
                more add-on features to make the online thrifting experience
                smoother than ever!
              </p>
              <p>
                <b> We exist to </b> make thrifting experiences super fun!
                That's exactly what we strive to achieve every day. Since
                inception, we have kept our direction firmly affixed, and all
                our efforts are well-directed to make thrifting enjoyable and
                fun-to-do.
              </p>
              <p>
                <b> Our living, breathing dream is to </b> offer ultimate
                convenience to both the parties involved in thrifting—the buyers
                and the stores. We desire to make products available at buyers'
                fingertips from anywhere around the world and fast-track and
                smoothen up the entire process, from buying/auctioning to
                selling to delivery at customer’s doorstep
              </p>
            </div>
          </div>
        </Container>
      </div>
    </CommonLayout>
  );
};

export default About;
