import { createAsyncThunk } from '@reduxjs/toolkit';
import thriftzee from '../../services/thrift';
import { csrf } from '../../utils/csrf';
import { productTypes, productsStatus } from '../../variables';
import { SortFunction } from '../sort/SortFunction';

export const getProductsData = createAsyncThunk(
  'storeLocations/fetchProducts',
  async ({ storeId, pageId, isRefresh = false }, { getState, dispatch }) => {
    const {
      loginReducer: { token }
    } = getState();
    let bearerToken;
    let isPublic = false;
    const { selectedItemsFilter, sortby } = getState().productsReducer;
    const auction = selectedItemsFilter.includes(productTypes.AUCTION);
    const buy = selectedItemsFilter.includes(productTypes.BUY);
    const available = selectedItemsFilter.includes(productsStatus.AVAILABLE);
    const bothType = auction && buy;
    const oneType = auction || buy;

    if (!token) {
      const csrfToken = await csrf();
      bearerToken = csrfToken;
      isPublic = true;
    } else {
      bearerToken = token;
    }
    const config = {
      headers: {
        Authorization: `Bearer ${bearerToken}`
      }
    };

    const { data } = await thriftzee.post(
      `${isPublic ? '/public' : ''}/products/search?status=${available ? productsStatus.AVAILABLE : productsStatus.ALL
      }${pageId ? `&pageId=${pageId}` : ''}${!bothType && oneType
        ? `&type=${auction ? productTypes.AUCTION : productTypes.BUY}`
        : ''
      }&sortby=${sortby}&isRefresh=${isRefresh}`,
      { storeId },
      config
    );
    let { products, count } = data.data;

    products = SortFunction(sortby, products);

    return { products, count };
  }
);
