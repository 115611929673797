import { Container, Typography, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import './NewsLetterStyle.css';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import EmailInput from '../../EmailInput';
import Button from '../../Button';
import { addSubscriber } from './redux/action';

const Newsletter = (props) => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleSubscribe = async () => {
    if (!email) {
      alert('Please enter a valid email address.');
      return;
    }

    try {
      dispatch(
        addSubscriber({
          email: email,
        })
      );
      setIsSubscribed(true);
    } catch (error) {
      alert('Something went wrong. Please try again.');
    }
  };

  return (
    <Box {...props}>
      <Container maxWidth="lg">
        <div
          className="newsletter"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            padding: mdScreen ? '30px 0' : '30px 10%',
          }}
        >
          {isSubscribed ? (
            <Typography
              variant="h5"
              component="h2"
              sx={{ pt: '5%', pb: '2%', fontWeight: '800' }}
            >
              Congratulations! You are now subscribed.
            </Typography>
          ) : (
            <>
              <Typography
                variant="h5"
                component="h2"
                sx={{ pt: '5%', pb: '2%', fontWeight: '800' }}
              >
                Stay Up-To-date on Thriftzee
              </Typography>
              <Typography variant="body1" component="p" sx={{ width: '100%' }}>
                Subscribe for weekly email updates about intriguing new products and exciting offers.
              </Typography>
              <form
                style={{
                  display: 'flex',
                  marginTop: '4%',
                  marginBottom: '5%',
                  width: mdScreen ? '100%' : '70%',
                  justifyContent: 'center',
                }}
              >
                <EmailInput
                  className="subscribe-email-field"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ width: '70%' }}
                  inputProps={{
                    style: {
                      fontSize: 14,
                      height: '43px',
                      padding: '0 14px',
                    },
                  }}
                />
                <Button
                  text="Subscribe"
                  onClick={handleSubscribe}
                  sx={{
                    borderRadius: '0 10px 10px 0',
                    textTransform: 'none',
                    width: { xs: '30%', sm: '30%' },
                    height: '40px',
                    boxShadow: 'none',
                  }}
                />
              </form>
            </>
          )}
        </div>
      </Container>
    </Box>
  );
};

export default Newsletter;
