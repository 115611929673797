import { combineReducers } from '@reduxjs/toolkit';
import loginReducer from './Modules/Login/reducer';
import storeReducer from './Modules/StoreLocation/reducer';
import productsReducer from './Modules/ProductLists/reducer';
import productDetailsReducer from './Modules/ProductDetails/reducer';
import bidHistoryReducer from './Modules/bidHistory/reducer';
import userHistoryReducer from './reducers/userHistory';
import contactReducer from '../src/Modules/Contact/reducer';
import userPositionReducer from './reducers/userPosition';
import featuredContentReducer from './Modules/LandingPage/redux/reducer';
import subscriberReducer from './Components/refactor/NewsLetter/redux/reducer';

const combinedReducer = combineReducers({
  loginReducer,
  storeReducer,
  productsReducer,
  productDetailsReducer,
  subscriberReducer,
  bidHistoryReducer,
  userHistoryReducer,
  contactReducer,
  userPositionReducer,
  featuredContentReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'loginReducer/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
