import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const checkoutUrl = location.state?.checkoutUrl;

  useEffect(() => {
    if (checkoutUrl) {
      window.location.href = checkoutUrl;
    } else {
      navigate('/error', { replace: true });
    }
  }, [checkoutUrl, navigate]);

  return null;
};

export default Checkout;
