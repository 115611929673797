import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LocationCheckboxes from './components/LocationCheckboxes';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import { getStoreLocationsData } from './actions';
import { getProductsData } from '../ProductLists/actions';
import { googleMapApiUrl } from '../../configs/config';
import InputBase from '@mui/material/InputBase';
import Grid from '@mui/material/Grid';
import logo from '../../resources/logo_white.png';
// import lock from '../../configs/authLock';
import { filterStoreLocations, commonStoreLocations } from './reducer';
import { setUserPosition } from '../../reducers/userPosition';
import CircularProgressLoader from '../../Components/CircularProgressLoader';
import { setSelectedItemsFilter } from '../ProductLists/reducer';
import { defaultStoreId, productsStatus } from '../../variables';
import './index.css';
import mapShop from '../../resources/mapShop.png';
import mapUser from '../../resources/mapUser.png';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
const Map = () => {
  // lock.hide();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const login = useSelector(state => state.loginReducer);
  const [loadGMap, setLoadGMap] = useState(true);
  const [coords, setCoords] = useState({});
  const storeReducer = useSelector(state => state.storeReducer);
  const productsReducer = useSelector(state => state.productsReducer);
  const [userLat, setUserLat] = useState(null);
  const [userLong, setUserLong] = useState(null);
  const [locationIds, setLocationIds] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [locationPermission, setLocationPermission] = useState(false);
  const [address, setAddress] = useState('');
  const [openDialog, setOpenDialog] = useState(true);

  const storeLocations = storeReducer.allStoreLocations;
  const { status } = storeReducer;

  const handleChange = e => {
    if (e.target.checked) {
      setLocationIds([...locationIds, e.target.value]);
    }

    if (!e.target.checked) {
      setLocationIds(
        locationIds.filter(location => location !== e.target.value)
      );
    }
  };

  const handleClose = () => {
    setOpenDialog(false); 
  };

  const handleClick = () => {
    dispatch(getProductsData({ storeId: locationIds }));
    dispatch(
      setSelectedItemsFilter([...locationIds, productsStatus.AVAILABLE])
    );
    dispatch(filterStoreLocations(locationIds));

    if (location.state?.navigateTo === '/buy') {
      dispatch(setSelectedItemsFilter(['available', 'buy']));
      navigate(location.state.navigateTo);
    } else if (location.state?.navigateTo === '/auction') {
      dispatch(setSelectedItemsFilter(['available', 'auction']));
      navigate(location.state.navigateTo);
    } else navigate('/products');
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      dispatch(setUserPosition({ latitude, longitude }));
      setCoords({
        latitude,
        longitude
      });
      setUserLat(latitude);
      setUserLong(longitude);
    });
    setLocationPermission(true);
  }, [dispatch]);

  useEffect(() => {
    const loadMap = () => {
      function initMap() {
        var noPoi = [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }]
          }
        ];

        const user = { lat: userLat, lng: userLong };
        const map = new window.google.maps.Map(document.getElementById('map'), {
          zoom: 13,
          center: user,
          mapTypeId: 'roadmap',
          disableDefaultUI: true
        });
        map.setOptions({ styles: noPoi });

        const contentString = (name, address) => {
          return `
          <div style="background-color:#ff5943; color:#fff; padding:10px">
            <h2 style="margin: 0;">${name}</h2>
            <p style="margin: 0; line-height: 18px;">${address}</p>
          </div>`;
        };
        new window.google.maps.Marker({
          title: 'My Location',
          position: user,
          map: map,
          icon: mapUser
        });

        storeLocations.forEach(element => {
          const storePosition = { lat: element.lat, lng: element.lon };
          const infowindow = new window.google.maps.InfoWindow({
            content: contentString(element.name, element.address),
            maxWidth: 300
          });
          const marker = new window.google.maps.Marker({
            title: element.name,
            position: storePosition,
            map,
            icon: mapShop
          });

          marker.addListener('click', () => {
            infowindow.open({
              anchor: marker,
              map,
              shouldFocus: false
            });
          });
        });

        const input = document.getElementById('pac-input');
        const searchBox = new window.google.maps.places.SearchBox(input);

        map.controls[window.google.maps.ControlPosition.TOP].push(input);

        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds());
        });

        let markers = [];

        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();

          if (places.length === 0) {
            return;
          }

          markers.forEach(marker => {
            marker.setMap(null);
          });
          markers = [];

          const bounds = new window.google.maps.LatLngBounds();

          places.forEach(place => {
            if (!place.geometry || !place.geometry.location) {
              return;
            }

            const icon = {
              url: place.icon,
              size: new window.google.maps.Size(71, 71),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(17, 34),
              scaledSize: new window.google.maps.Size(25, 25)
            };

            // Create a marker for each place.
            markers.push(
              new window.google.maps.Marker({
                map,
                icon,
                title: place.name,
                position: place.geometry.location
              })
            );
            setAddress(places[0].formatted_address);
            // console.log(place.geometry.location.lng());
            setUserLat(place.geometry.location.lat());
            setUserLong(place.geometry.location.lng());
            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
        });
      }
      const gscript = document.createElement('script');
      gscript.src = googleMapApiUrl;
      gscript.async = true;
      gscript.defer = true;

      document.body.appendChild(gscript);
      window.initMap = initMap;
    };
    if (coords.latitude && coords.longitude && loadGMap) {
      setLoadGMap(false);
      loadMap();
    }
  }, [loadGMap, coords, userLat, userLong, storeLocations]);

  useEffect(() => {
    if (locationPermission && userLat && userLong) {
      setMapLoaded(true);
      dispatch(
        getStoreLocationsData({ latitude: userLat, longitude: userLong })
      );
    }
  }, [dispatch, userLat, userLong, login, locationPermission]);

  useEffect(() => {
    return () => setLocationPermission(false);
  }, []);
  useEffect(() => {
    dispatch(commonStoreLocations(address));
  }, [dispatch, address]);

  return (
    <div>
    <Dialog
      className="location-picker"
      open={openDialog}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth={'sm'}
      fullWidth
      sx={{
        margin: 0
      }}
    >
      <div className="location-picker-container">
        <DialogTitle id="scroll-dialog-title" sx={{ textAlign: 'center', position: 'relative' }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="mobile-only" style={{ marginBottom: '10px' }}>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                {' '}
              </Grid>
              <Grid item xs={8}>
                <img
                  src={logo}
                  alt="logo"
                  style={{ height: '40px', margin: 'auto' }}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </div>
          <Typography
            variant="h5"
            component="div"
            sx={{ fontWeight: '700' }}
            className="location-picker-title"
          >
            Pick a Location
          </Typography>
          <Typography
            variant="p"
            component="p"
            sx={{ fontSize: '14px', fontWeight: 'normal' }}
            className="location-picker-description"
          >
            Select your nearby stores to see available products.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <InputBase
            style={{
              display: mapLoaded ? 'span' : 'none'
            }}
            id="pac-input"
            sx={{ ml: 1, flex: 1, fontSize: 10, color: '#000' }}
            placeholder="search for a location"
            inputProps={{ 'aria-label': 'search for a location' }}
          />
          <div
            id="map"
            style={{
              width: '100%',
              height: '250px',
              border: '10px solid #E3E3E3',
              zIndex: '0'
            }}
          ></div>
        </DialogContent>
      </div>
        <DialogContent>
          {locationPermission && (!userLat || !userLong) ? (
            <div style={{ textAlign: 'center' }}>
              Please allow location permission to see your nearby store
            </div>
          ) : storeReducer.status === 'success' && !storeLocations.length ? (
            <></>
          ) : storeLocations.length ? (
            <Typography
              variant="h6"
              component="h2"
              sx={{
                textAlign: 'left',
                fontSize: '18px',
                marginTop: '10px',
                marginBottom: '5px'
              }}
            >
              {storeLocations.length === 1
                ? storeLocations[0].id === defaultStoreId && (
                    <>
                      <Box mb={1}>
                        <Alert severity="error">Store Not Found</Alert>
                      </Box>
                      Our Default Store
                    </>
                  )
                : 'Nearby Locations:'}
            </Typography>
          ) : (
            <></>
          )}
          {locationPermission && status === 'pending' ? (
            <CircularProgressLoader />
          ) : locationPermission &&
            status === 'success' &&
            storeLocations.length &&
            userLat &&
            userLong ? (
            <LocationCheckboxes
              locations={storeLocations}
              handleChange={handleChange}
            />
          ) : (
            <></>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '10px'
            }}
          >
            <LoadingButton
              className="pick-location"
              variant="contained"
              sx={{
                textTransform: 'none',
                borderRadius: '30px',
                height: '50px',
                fontSize: '14px',
                fontWeight: '700',
                width: '50%'
              }}
              onClick={handleClick}
              disabled={!locationIds.length}
              loading={productsReducer.status === 'pending'}
            >
              Confirm
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Map;
