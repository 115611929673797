import React from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Tooltip,
  CardActions,
  Box,
  Button,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
//import moment from 'moment';
import StyledButton from '../../../Components/StyledButton';
//import BidCountdown from '../../ProductDetails/components/BidCountdown';
import { productTypes } from '../../../variables';

const Cards = ({
  product,
  statusImg,
 // handleQuickBid,
  handleClick,
  handleQuickBuy
}) => {
  const theme = useTheme();
  const mdScreen = useMediaQuery(theme.breakpoints.down('md'));
  const endDate = new Date(product.displayExpireDate);
  return (
    <>
      <Card
        className="product-list-card"
        elevation={0}
        sx={{
          width: '100%',
          '&:hover': { boxShadow: '0px 0px 10px #D3D3D3' }
        }}
      >
         {statusImg && (
          <div className="icon-over-img">
            <img src={statusImg} alt="closed" className="auction-closed-icon" />
          </div>
        )}
        <CardMedia
          className="product-list-card-img"
          component="img"
          image={product?.images?.[0] || product?.image}
          alt={product.name}
          sx={{
            padding: '10px',
            borderRadius: '20px',
            cursor: 'pointer',
            height: '260px'
          }}
          onClick={() => handleClick(product)}
        />

        <CardContent
          sx={{
            textAlign: { xs: 'center', md: 'left' },
            height: '130px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          className="product-text"
        >
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="product-title"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer'
            }}
            onClick={() => handleClick(product)}
          >
            <Tooltip
              title={product.productName + ' ' + product.storeName}
              placement="top-start"
            >
              <span>{product.productName} </span>
            </Tooltip>
          </Typography>
          {/* {product.totalBids >= 0 && product.type === productTypes.AUCTION && (
            <Typography
              variant="subtitle2"
              color="text.secondary"
              className="product-details"
            >
              Current Bids:{' '}
              <span style={{ color: '#FF5943' }}>{product.totalBids}</span>
            </Typography>
          )}
          {product.endDate && product.type === productTypes.AUCTION && (
            <Typography
              variant="subtitle2"
              color="text.secondary"
              className="product-details"
            >
              {product.endDate < new Date().getTime() ? (
                <>
                  Auction Closed:{' '}
                  <span style={{ color: '#FF5943' }}>
                    {moment(+product.endDate).fromNow()}
                  </span>
                </>
              ) : (
                <>
                  Auction Closes In:{' '}
                  <span style={{ color: '#FF5943' }}>
                    <BidCountdown date={product.endDate} />
                  </span>
                </>
              )}
            </Typography>
          )} */}
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            className="Expire-date"
          >
            {
              <>
                Available on:  <span style={{ fontSize: 'medium' }}>
                  {endDate.toLocaleDateString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                  })}</span>
              </>
            }
          </Typography>
          <Typography
            className="product-view-details"
            onClick={() => handleClick(product)}
          >
            View details
          </Typography>
        </CardContent>
        <CardActions>
          <Box
            sx={{
              display: { xs: 'block', md: 'flex' },
              width: '100%',
              justifyContent: 'space-between',
              p: '2%',
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            <Typography
              variant="h5"
              color={{ xs: 'primary.main', md: 'secondary.main' }}
              sx={{ fontWeight: 700, py: { xs: 1, md: '5px' } }}
            >
              ৳ {product.lastBid || product.price}
            </Typography>
            {/* {mdScreen &&
              product.type === productTypes.AUCTION &&
              product.endDate > new Date().getTime() && (
                <StyledButton
                  onClick={() => handleQuickBid(product)}
                  pm="20px"
                  width="123px"
                >
                  Place Bid
                </StyledButton>
              )} */}
            {/* {!mdScreen &&
              product.type === productTypes.AUCTION &&
              product.endDate > new Date().getTime() && (
                <Button
                  variant="contained"
                  sx={{
                    width: '130px',
                    bgcolor: '#193C46',
                    textTransform: 'none',
                    '&:hover': {
                      bgcolor: '#FF5943',
                      color: 'white'
                    },
                    borderRadius: '50px',
                    fontSize: '20px',
                    fontWeight: 700,
                    letterSpacing: '-0.33px'
                  }}
                  onClick={() => handleQuickBid(product)}
                >
                  Place bid
                </Button>
              )} */}
            {mdScreen && product.type === productTypes.BUY && !product.sold && (
              <StyledButton onClick={() => handleQuickBuy(product)} pm="49px">
                Buy
              </StyledButton>
            )}
            {!mdScreen && product.type === productTypes.BUY && !product.sold && (
              <Button
                variant="contained"
                sx={{
                  width: '130px',
                  px: '35px',
                  bgcolor: '#193C46',
                  textTransform: 'none',
                  '&:hover': {
                    bgcolor: '#FF5943',
                    color: 'white'
                  },
                  borderRadius: '50px',
                  fontSize: '20px',
                  fontWeight: 700,
                  letterSpacing: '-0.33px'
                }}
                onClick={() => handleQuickBuy(product)}
              >
                Buy
              </Button>
            )}
          </Box>
        </CardActions>
      </Card>
    </>
  );
};

export default Cards;
