import { createSlice } from '@reduxjs/toolkit';
import {
    addSubscriber
} from './action';

const initialState = {
    addSubscriberStatus: 'idle',
    error: null
};

export const addSubscriberSlice = createSlice({
    name: 'subscriberReducer',
    initialState,
    reducers: {
        setAddSubscriber: (state, action) => {
            state.addSubscriber = action.payload;
        },

        setAddSubscriberStatus: (state, action) => {
            state.status = action.payload;
        },

        resetAddSubscriber: (state, action) => ({
            ...state,
            ...initialState
        })
    },
    extraReducers: {
        [addSubscriber.pending]: (state, action) => ({
            ...state,
            addSubscriberStatus: 'pending'
        }),
        [addSubscriber.fulfilled]: (state, action) => ({
            ...state,
            addSubscriberStatus: 'success'
        }),
        [addSubscriber.rejected]: (state, action) => ({
            ...state,
            addSubscriberStatus: 'error'
        }),
    }
});

const { actions, reducer } = addSubscriberSlice;
export const {
    setAddSubscriber,
    setAddSubscriberStatus,
    resetAddSubscriber
} = actions;
export default reducer;
